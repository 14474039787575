import { useState } from "react"
import Navbar from "./elements/navbar"
import Footer from "./elements/footer"
import NotificationBanner from "./elements/notification-banner"
import clsx from "clsx"


export default function Layout({ children, global, isLandingPage = false }) {
  const { navbar, footer, notificationBanner} = global.attributes
  const [bannerIsShown, setBannerIsShown] = useState(true)

  return (
    <>
      <div className="flex flex-col justify-between min-h-screen">
        {/* Aligned to the top */}
        <div className="sticky top-0 z-50 w-100">
          
          {/* The top bars: nav and notification */}
          <header className="justify-between">
            {notificationBanner && bannerIsShown && (
              <NotificationBanner
                data={notificationBanner}
                closeSelf={() => setBannerIsShown(false)}
              />
            )}
            <Navbar navbar={navbar} pageContext={global.attributes.pageContext} />
          </header>
        </div>

        {/* <main className={clsx("relative", staatliches.className, pacifico.className, lato.className)}> */}
        <main className={clsx("relative")}>
          {children}
        </main>
      
        {/* Aligned to the bottom */}
        {!isLandingPage && (
        <Footer footer={footer} locale={global.attributes.pageContext?.locale} />
        )}
      </div>
    </>
  )
}