import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import clsx from "clsx"
import { mediaPropTypes, linkPropTypes } from "../../../utils/types"
import { MdMenu } from "react-icons/md"
import MobileNavMenu from "../mobile-nav-menu"
import CustomLink from "../custom-link"
import AccountButton from "../account-button"

import { usePathname } from "next/navigation"

import LogoButton from "./logo-button"
import ShoppingBag from "../../ecwid/shopping-bag"

const Navbar = ({ navbar, pageContext }) => {
  const router = useRouter()
  const pathname = usePathname()

  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)
  const [isHome, setIsHome] = useState(true)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    if (isHome) {
      const detectScrollY = () => {
        if (window.scrollY > 5) {
          setIsScrolled(true)
        } else {
          setIsScrolled(false)
        }
      }

      window.addEventListener("scroll", detectScrollY)

      return () => {
        window.removeEventListener("scroll", detectScrollY)
      }
    }
  }, [isHome])

  useEffect(() => {
    pathname === "/" ? setIsHome(true) : setIsHome(false)
  }, [pathname])


  return (
      <>
      {/* The actual navbar */}
      {/* <header className={clsx(
        "header bg-sunrise shadow-md flex",
        {
          "!bg-sunrise !border-sunrise": !isHome || isScrolled,
        }
      )}> */}

        {/* Display the mobile menu */}
        {mobileMenuIsShown && (
          <MobileNavMenu
            navbar={navbar}
            closeSelf={() => setMobileMenuIsShown(false)}
          />
        )}

        <h1 className="w-10 flex items-center">
          {/* Menu part 2 */}
          <button
            title="Show Menu"
            name="Show Menu"
            onClick={() => setMobileMenuIsShown(true)}
            className="p-1 display:inline md:hidden hover:text-crimson focus:outline-none">
            <MdMenu className="h-8 w-auto" />
          </button>
        </h1>

        {(navbar !== null) ?
          <nav className={clsx(
            "nav w-100 font-semibold text-lg flex bg-sunrise justify-center shadow-md mx-auto",
            {
              "text-white group-hover:text-crimson": isHome && !isScrolled,
            }
          )}>
            {/* Logo home button */}
            <div className="flex items-center space-x-4">
              <LogoButton media={navbar.logo}></LogoButton>
            </div>

            {/* Menu on desktop */}
              <ul className="hidden list-none md:flex space-x-6 items-center">
                <li key="store-menu" className="text-primary hover:text-crimson px-2 text-md md:text-1g">
                  {<CustomLink link={{ id: 'store-link', url: '/store', text: 'Shop', newTab: false }} locale={router.locale}>Shop</CustomLink>}
                </li>
                {navbar.links.map((navLink) => (
                  <li key={navLink.id} className="text-primary hover:text-crimson text-md md:text-1g">
                    <CustomLink link={navLink} locale={router.locale}>
                      {navLink.text}
                    </CustomLink>
                  </li>
                ))}
                <li className="text-primary hover:text-crimson text-md md:text-1g px-2">
                  <AccountButton />
                </li>
                <li className="text-primary hover:text-crimson text-md md:text-1g px-2">
                  <ShoppingBag storeId="100774270" />
                </li>
              </ul>
            
            {/* <ul className="hidden list-none md:flex flex-row-reverse"> */}

            {/* <li>
            {navbar.ctaButton && <ButtonLink 
              compact
              button={navbar.ctaButton}
              appearance={getButtonAppearance(navbar.ctaButton.type, "primary")}
              key={navbar.ctaButton.id}
            />}
          </li> */}

            {/* </ul> */}
          </nav> : <></>}
      {/* </header> */}
    </>
  )
}

Navbar.propTypes = {
  navbar: PropTypes.shape({
    logo: PropTypes.shape({
      media: mediaPropTypes,
      url: PropTypes.string,
    }),
    links: PropTypes.arrayOf(linkPropTypes),
  }),
  initialLocale: PropTypes.string,
}

export default Navbar
