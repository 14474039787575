import Markdown from "react-markdown"
import classNames from "classnames"
import clsx from "clsx"
import { MdClose } from "react-icons/md"
import { useEffect, useState } from "react"
import { usePathname } from "next/navigation"

const NotificationBanner = ({ data: { text, type }, closeSelf }) => {
  const [isHome, setIsHome] = useState(true)
  // const [isScrolled, setIsScrolled] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    pathname === "/" ? setIsHome(true) : setIsHome(false)
  }, [pathname])

  return (
    <div
      className={clsx(
        // Common classes
        //"text-white px-2 py-2 sticky top-0 group items z-50",
        "text-white px-2 py-2 group items",
        {
          // Apply theme based on notification type
          "bg-chocolate": type === "info",
          "bg-sunset": type === "warning",
          "bg-alert": type === "alert",
          "!fixed": isHome,
        }
      )}
    >
      <div className="container flex flex-row justify-center items-center ">
        <div className="rich-text-banner flex">
          <Markdown>{text}</Markdown>
        </div>
        <button onClick={closeSelf} className="px-1 py-1 flex-shrink-0">
          <MdClose className="h-6 w-auto" color="#fff" />
        </button>
      </div>
    </div>
  )
}

export default NotificationBanner
